import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../components/layout'
import ServiceBlock from '../../components/service-block'

const AboutPage = ({ data }) => {
  const {
    frontmatter: { title, caracteristics, image, seoTitle },
  } = data.markdownRemark

  return (
    <Layout title={title} seoTitle={seoTitle}>
      <div className="about-area rn-section-gap">
        <div className="about-wrapper">
          <div className="container">
            <div className="row align-items-top">
              <div className="col-lg-4 col-md-12">
                <div className="thumbnail">
                  <Img
                    className="w-100"
                    fluid={image.childImageSharp.fluid}
                    alt="About images"
                  />
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="row service-one-wrapper">
                  {caracteristics.map(
                    ({ title, description, iconName }, caracIndex) => (
                      <ServiceBlock
                        key={`carac_${caracIndex}`}
                        lgSize="6"
                        title={title}
                        description={description}
                        iconName={iconName}
                        bgColor="1"
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const query = graphql`
  query AboutPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        seoTitle

        caracteristics {
          iconName
          title
          description
        }

        image {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
